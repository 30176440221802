import React from "react"
import Layout from "../components/layout"

import {GatsbySeo} from "gatsby-plugin-next-seo/src/meta/gatsby-seo";
import HeaderH2 from "../components/headerH2";

export default function LinkfiveBiggestChallenge() {
  return (
    <>
      <GatsbySeo title="The 3 Biggest Problems with in-App Subscriptions and how to fix them"
                 description={`Adding subscriptions to your own app is time-consuming and more than a hassle. Especially when you develop an app with flutter that is intended for several platforms by nature.`}
      />
      <Layout fullWave="false" bottomWave="false" callToAction="true" utmSource="showcase">
        <div className="pt-24 pb-12 bg-l6-background">
          <div
            className="container max-w-5xl px-3 mx-auto  md:flex-row items-center lg:mb-12 lg:mt-12">
            <div
              className="lg:mb-10 mb-0 md:pt-16 md:w-full flex flex-col items-start">
              <h1 className="font-extrabold leading-10 tracking-tight sm:leading-none md:text-6xl sm:text-4xl text-2xl">
                The 3 Biggest Problems with in-App Subscriptions and how to fix them
              </h1>
              <p className="my-5 text-lg text-gray-600 md:max-w-4xl leading-relaxed md:text-xl md:mx-0 ">
                Adding subscriptions to your own app is time-consuming and more than a hassle. Especially when you develop an app with flutter that is intended for several platforms by nature.
                Here is a brief overview of the existing problems with in-app subscriptions and how you can fix them.</p>
            </div>
          </div>
        </div>

        <div className="pb-12"/>

        <section className="bg-white pt-4" >
          <div className="container max-w-4xl mx-auto m-8 text-justify">
            <HeaderH2
              headerText="Subscription Validation"
            />
            <div className="flex flex-wrap md:mt-12">
              <div className="p-6">
                <h3 className="mt-2 mb-8 md:text-3xl text-xl font-extrabold leading-10 tracking-tight text-gray-800">
                  Subscriptions can be cancelled inside the App Store without you knowing about it.
                </h3>
                <p className=" mt-4 text-xl leading-7 text-gray-800">
                  When a user buys a subscription in your app, there may be a successful purchase event.
                  But what happens if the user cancels the subscription in the App Store without using your app and gets
                  the full amount back?
                </p>
                <p className=" mt-4 text-xl leading-7 text-gray-800">
                  Your app and your server don't know about it at first because the App Store is only connected to your
                  running app, and even then only when the app is active and the app code asks for it.

                </p>
                <p className=" mt-8 text-2xl font-bold leading-7 text-gray-800">
                  Example:
                </p>
                <p className=" mt-4 text-xl leading-7 text-gray-800">
                  A user purchases the annual subscription for €9.99 with a successful purchase event.
                  The app unlocks all purchased premium features for the user. The user then cancels the subscription in
                  the App Store (the user gets 100% back if they cancel within 15 minutes).
                  The premium functions are still activated inside the app and the user can still use the premium
                  functions for a year without any costs.
                </p>
                <p className=" mt-4 text-xl leading-7 text-gray-800">
                  Now you might think: If the user uses the app, I can simply ask the App Store whether the purchase is
                  still active or not, right?
                  Well, first of all it's a bit more complicated than that because, for example, Apple requires a
                  subscription key plus the purchase receipt to communicate with the Apple API. Then you can ask whether
                  the subscription purchase is still valid or not.
                </p>
                <p className=" mt-4 text-xl leading-7 text-gray-800">
                  But what if the user doesn't open the app after cancelling the purchase and only uses the web version
                  or another platform like Android?
                  Yes, then you no longer have a chance to find out if a purchase is still valid with you only having an
                  app-only implementation. You would need a server that either regularly checks whether a purchase is
                  still valid or you use the server to server notifications offered by Apple, Google or Stripe (which
                  LinkFive uses).
                </p>

                <h4 className=" mt-8 text-2xl font-bold leading-7 text-gray-800">
                  How LinkFive solves subscription validation:
                </h4>
                <p className=" mt-4 text-xl leading-7 text-gray-800">
                  LinkFive automatically validates all App Store purchases made through the LinkFive platform.
                  When the user manually cancels a purchase, the LinkFive server immediately receives a notification from Apple, Google or Stripe that the user has cancelled the purchase.
                  The subscription is immediately inactive for all connected user sessions and the app or website receives updated information and deactivates its premium content.
                </p>
                <p className=" mt-4 text-xl leading-7 text-gray-800">
                  Apple, Google and Stripe offer server-to-server notifications in addition to their API.
                  The notifications enable the app servers to map the entire subscription lifecycle without interacting with the API. You receive a notification when a user buys, ends, cancels or renews a subscription.
                  By adding each of these notifications to the user session, you can always be sure that the purchased subscription is active, valid and not a false positive.
                </p>
              </div>
            </div>
          </div>
        </section>


        <section className="bg-white pt-4">
          <div className="container max-w-4xl mx-auto m-8 text-justify">
            <HeaderH2
              headerText="Cross-platform Subscriptions"
            />
            <div className="flex flex-wrap md:mt-12">
              <div className="p-6">
                <h3 className="mt-2 mb-8 md:text-3xl text-xl font-extrabold leading-10 tracking-tight text-gray-800">
                  The purchase of a subscription on one platform does not automatically unlock the same functionality on other platforms.
                </h3>
                <p className=" mt-4 text-xl leading-7 text-gray-800">
                  When a user purchases a subscription in your app, the purchase is initially only available on the device to which your App Store account is linked. So how do the premium features get onto other devices?
                </p>

                <p className=" mt-8 text-2xl font-bold leading-7 text-gray-800">
                  Example:
                </p>
                <p className=" mt-4 text-xl leading-7 text-gray-800">
                  Your user purchases a subscription from the Google Play Store on the Android device in your Flutter app. The user not only uses their Android device, but also the web version of the app. If the user now uses the web version, no premium features are available. The same applies to the iOS version.
                </p>
                <p className=" mt-4 text-xl leading-7 text-gray-800">
                  The most common use case for cross-platform subscriptions is not iOS and Android, as not many users use an Android and an iOS device at the same time. The most common use case is to use an Android or iOS device with the web version. This means that an iPhone user is most likely active in the web version and on the iPhone at the same time.
                </p>

                <h4 className=" mt-8 text-2xl font-bold leading-7 text-gray-800">
                  How LinkFive is solving cross-platform subscriptions:
                </h4>
                <p className=" mt-4 text-xl leading-7 text-gray-800">
                  The purchase must be linked to the user. If the user logs on to another platform, the premium content can be unlocked for the user.
                  However, it is important to know that you do not necessarily need to have an account to buy a subscription. LinkFive therefore uses user sessions to link a purchase to an ID.
                </p>
                <p className=" mt-4 text-xl leading-7 text-gray-800">
                  When a user purchases a subscription, the subscription is initially associated with a user session that exists only on the current device and is unique.
                  The user session can then be linked to an internal user ID (this ID usually comes from your authentication system).
                  If a user now logs in with the same account on another device, the user session on the other device is also linked to the internal user ID, so that both user sessions are now linked to one internal user ID.
                  The purchased subscription is now active for all platforms and it really doesn't matter whether it is used on Android, iOS, Web, Mac or Windows.
                </p>
              </div>
            </div>
          </div>
        </section>



        <section className="bg-white pt-4">
          <div className="container max-w-4xl mx-auto m-8 text-justify">
            <HeaderH2
              headerText="Tailor your offer to the User"
            />

            <div className="flex flex-wrap md:mt-12">
              <div className="p-6">
                <h3 className="mt-2 mb-8 md:text-3xl text-xl font-extrabold leading-10 tracking-tight text-gray-800">
                  Purchasing behaviour is different in every country. There is no offer that works worldwide.
                </h3>
                <p className=" mt-4 text-xl leading-7 text-gray-800">
                  A great offer is only a great offer if the user makes a purchase. If someone is offered two subscriptions at the same time and one of them is not eligible, they will choose the one that is eligible or none at all.
                  If a user is offered with two subscriptions which are eligible, the user will usually choose one of the two offers rather than none of them.
                </p>

                <p className=" mt-4 text-xl leading-7 text-gray-800">
                  One of our customers told me that a subscription with a long term, e.g. 12 months plan, sells better in Germany than the same offer in Italy.
                  This is because the buying behaviour of customers in Germany is different than in Italy. Italians are more likely to opt for a shorter term than a longer one and in Germany, people are more likely to choose the longer term than the shorter one.
                </p>
                <p className=" mt-4 text-xl leading-7 text-gray-800">
                  It therefore only makes sense to have a different subscription offer in Germany than in Italy. So in Germany you would for example offer a subscription of 1 month and 12 months and in Italy of 1 month and 3 months or 6 months.
                </p>

                <h4 className=" mt-8 text-2xl font-bold leading-7 text-gray-800">
                  How LinkFive creates a tailored subscription offer for every user:
                </h4>
                <p className=" mt-4 text-xl leading-7 text-gray-800">
                  The subscription offer must be dynamically adjustable for each country or for each user. This is not possible if the subscriptions are written into the code.
                  You can solve this problem by asking your server which subscriptions should be displayed and your server will respond with the correct subscription offer.
                </p>
                <p className=" mt-4 text-xl leading-7 text-gray-800">
                  If you use the LinkFive plugin, each user receives their own subscription offer. You can configure this inside the Subscription-Playout section on the LinkFive Web-App.
                  You can define which subscriptions are displayed in Germany, Italy or anywhere in the world. This way you can make a different offer for customers in Italy and in Germany.
                  You can even activate an offer for a specific period only, launching a Summer Sale, Winter Sale or even a Weekend Sale and offering subscriptions at a reduced price.
                </p>
              </div>
            </div>
          </div>
        </section>
      </Layout>
    </>
  )
}
